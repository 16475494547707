<template>
  <div style="overflow-x:hidden !important">
    <!-- <LoadingBar v-if="showHideSpinner" /> -->
    <AppNavbar v-if="$route.name == 'index' || $route.name == 'contact'"/>
    <nuxt />
  </div>
</template>

<script>
export default {
  components: {
    AppNavbar: () => import('@/components/AppNavbar'),
    LoadingBar: () => import('@/components/LoadingBar'),
  },
  beforeCreate() {
    this.showHideSpinner = true;
  },
  mounted() {
    this.showHideSpinner = false;
  },
  data() {
    return {
      showHideSpinner: true
    };
  }
}
</script>

<style>
html {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}


</style>
