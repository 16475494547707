import Vue from 'vue';
import Slick from 'vue-slick';
import '~/node_modules/slick-carousel/slick/slick.css';
import '~/node_modules/slick-carousel/slick/slick-theme.css';
import VueScrollReveal from 'vue-scroll-reveal';
import smoothscroll from 'smoothscroll-polyfill';
import $ from 'jquery';
import 'img-comparison-slider';
var VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo, {
    container: 'body',
    duration: 500,
    easing: 'ease',
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true,
  })

Vue.use(VueScrollReveal, {
    class: 'v-scroll-reveal', // A CSS class applied to elements with the v-scroll-reveal directive; useful for animation overrides.
    duration: 800,
    scale: 1,
    distance: '50px',
    mobile: true,
    delay: 500,
});

Vue.component('slick', Slick);
Vue.use(Slick);
export default Slick;


// window.addEventListener('scroll', () => {
//     document.body.style.setProperty('--scroll',window.pageYOffset / (document.body.offsetHeight - window.innerHeight));
// }, false);


smoothscroll.polyfill();